@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-capsule-gradient {
  background: linear-gradient(123.55deg, #4d4d4d 11.74%, #1a1a1a 87.03%);
}

.bg-capsule-gradient_alpha,
button.code:hover {
  background: linear-gradient(
    123.55deg,
    rgba(255, 255, 255, 0.4) 11.74%,
    rgba(255, 255, 255, 0.1) 87.03%
  );
}

a.bg-capsule-gradient:hover,
button.bg-capsule-gradient:hover {
  background: linear-gradient(123.55deg, #1a1a1a 11.74%, #4d4d4d 87.03%);
}

.bg-code-gradient {
  background: linear-gradient(
    123.55deg,
    rgba(0, 0, 0, 0.4) 11.74%,
    rgba(1, 1, 1, 0.1) 87.03%
  );
}

/* Dark themed code editor */
code[class*='language-'],
pre[class*='language-'] {
  color: #fff;
  background: 0 0;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  text-align: left;
  text-shadow: 0 -0.1em 0.2em #000;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
:not(pre) > code[class*='language-'],
pre[class*='language-'] {
  background: #141414;
}
pre[class*='language-'] {
  border-radius: 0.5em;
  border: 0.3em solid #545454;
  box-shadow: 1px 1px 0.5em #000 inset;
  margin: 0.5em 0;
  overflow: auto;
  padding: 1em;
}
pre[class*='language-']::-moz-selection {
  background: #27292a;
}
pre[class*='language-']::selection {
  background: #27292a;
}
code[class*='language-'] ::-moz-selection,
code[class*='language-']::-moz-selection,
pre[class*='language-'] ::-moz-selection,
pre[class*='language-']::-moz-selection {
  text-shadow: none;
  background: hsla(0, 0%, 93%, 0.15);
}
code[class*='language-'] ::selection,
code[class*='language-']::selection,
pre[class*='language-'] ::selection,
pre[class*='language-']::selection {
  text-shadow: none;
  background: hsla(0, 0%, 93%, 0.15);
}
:not(pre) > code[class*='language-'] {
  border-radius: 0.3em;
  border: 0.13em solid #545454;
  box-shadow: 1px 1px 0.3em -0.1em #000 inset;
  padding: 0.15em 0.2em 0.05em;
  white-space: normal;
}
.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
  color: #777;
}
.token.punctuation {
  opacity: 0.7;
}
.token.namespace {
  opacity: 0.7;
}
.token.boolean,
.token.deleted,
.token.number,
.token.tag {
  color: #ce6849;
}
.token.builtin,
.token.constant,
.token.keyword,
.token.property,
.token.selector,
.token.symbol {
  color: #f9ed99;
}
.language-css .token.string,
.style .token.string,
.token.attr-name,
.token.attr-value,
.token.char,
.token.entity,
.token.inserted,
.token.operator,
.token.string,
.token.url,
.token.variable {
  color: #909e6a;
}
.token.atrule {
  color: #7385a5;
}
.token.important,
.token.regex {
  color: #e8c062;
}
.token.bold,
.token.important {
  font-weight: 700;
}
.token.italic {
  font-style: italic;
}
.token.entity {
  cursor: help;
}
.language-markup .token.attr-name,
.language-markup .token.punctuation,
.language-markup .token.tag {
  color: #ac885c;
}
.token {
  position: relative;
  z-index: 1;
}
.line-highlight.line-highlight {
  background: hsla(0, 0%, 33%, 0.25);
  background: linear-gradient(
    to right,
    hsla(0, 0%, 33%, 0.1) 70%,
    hsla(0, 0%, 33%, 0)
  );
  border-bottom: 1px dashed #545454;
  border-top: 1px dashed #545454;
  margin-top: 0.75em;
  z-index: 0;
}
.line-highlight.line-highlight:before,
.line-highlight.line-highlight[data-end]:after {
  background-color: #8693a6;
  color: #f4f1ef;
}

/* text flying on home page */

.fade-enter {
  opacity: 0;
  transform: translateX(-20px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.fade-exit {
  visibility: hidden;
  height: 0px;
}

.fade-exit-active {
  visibility: hidden;
  height: 0px;
}
